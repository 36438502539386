import React, { useState, useRef, useEffect } from "react";
import AppLayout from 'layouts/AppLayout';
import { createGlobalStyle } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { Toast } from 'bootstrap';

// Global styles
const GlobalStyle = createGlobalStyle`
	table {
		font-size: 13px;

		th {
			white-space: nowrap;

			&:nth-child(1) {
				
			}

			&:nth-child(2) {
				width: 20px;
			}

			&:nth-child(3) {
				width: 100px;
			}

			&:nth-child(4) {
				width: 100px;
			}

			&:nth-child(5) {
				width: 50px;
			}

			&:nth-child(6) {
				width: 120px;
			}

			&:nth-child(7) {
				width: 40px;
			}

			&:nth-child(8) {
				width: 40px;
			}

			&:nth-child(9) {
				width: 40px;
			}
		}

		th,td {
			vertical-align: middle;

			&:nth-child(9) {
				width: 40px;
				white-space: nowrap;
			}
		}
	}
`;

// Axios cancel token
let cancelTokenSource = null;

export default function Stations() {
	// State
	const [ stations, setStations ] = useState([]);
	const [ errors, setErrors ] = useState({});
	const [ toast, setToast ] = useState(false);

	// Ref
	const idRef = useRef(null);
	const nameRef = useRef(null);
	const addressRef = useRef(null);
	const postalcodeRef = useRef(null);
	const provinceRef = useRef(null);
	const cityRef = useRef(null);
	const octanIdRef = useRef(null);
	const ipRef = useRef(null);
	const ipBackupRef = useRef(null);
	const portRef = useRef(null);
	const userRef = useRef(null);
	const passwordRef = useRef(null);
	const companyCodeRef = useRef(null);
	const toastRef = useRef(null);

	useEffect(() => {
		cancelTokenSource = axios.CancelToken.source();

  		getStations(); 

  		return function cleanup() {
           	cancelTokenSource.cancel();
        }
  	}, []);

  	useEffect(() => {
        let bsToast = Toast.getInstance(toastRef.current);
        if ( !bsToast ) {
            // initialize Toast
            bsToast = new Toast(toastRef.current)
            // hide after init
            bsToast.hide();
            setToast(false);
        } else {
            // toggle
            toast ? bsToast.show() : bsToast.hide()
        }
  	}, [toast]);

  	function showToast(msg) {
  		setToast(msg);
  		setTimeout(() => {
  			setToast(false);
  		}, 3000);
  	}

  	function getStations() {
  		// Ajax
		axios.get('/stations/list', {
			cancelToken: cancelTokenSource.token
		})
	  	.then((response) => {
	  		setStations(response.data);
	  	})
	  	.catch((error) => {
	  		if ( axios.isCancel(error) ) return;
	  		setStations([]);
	  	});		
  	}

  	function getStation(event, id) {
		event.preventDefault();

		// Remove errors
		setErrors({});

		// Ajax
		axios.get('/stations/get/' + id, {
		    cancelToken: cancelTokenSource.token
	  	})
	  	.then((response) => {
	  		// Set data
	  		idRef.current.value = response.data.id;
	  		nameRef.current.value = response.data.name;
	  		addressRef.current.value = response.data.address ?? '';
	  		postalcodeRef.current.value = response.data.postalcode ?? '';
	  		provinceRef.current.value = response.data.province ?? '';
	  		cityRef.current.value = response.data.city ?? '';
	  		octanIdRef.current.value = response.data.octan_id;
	  		ipRef.current.value = response.data.ip;
	  		ipBackupRef.current.value = response.data.ip_backup;
	  		portRef.current.value = response.data.port;
	  		userRef.current.value = response.data.user;
	  		passwordRef.current.value = response.data.password;
	  		companyCodeRef.current.value = response.data.company_code;
	  	});	
	}

	function saveStation() {
		// Remove errors
		setErrors({});

		// Ajax
		axios.post('/stations/save', {
		    id: idRef.current.value,
		    name: nameRef.current.value,
		    address: addressRef.current.value,
		    postalcode: postalcodeRef.current.value,
		    province: provinceRef.current.value,
		    city: cityRef.current.value,
		    octan_id: octanIdRef.current.value,
		    ip: ipRef.current.value,
		    ip_backup: ipBackupRef.current.value,
		    port: portRef.current.value,
		    user: userRef.current.value,
		    password: passwordRef.current.value,
		    company_code: companyCodeRef.current.value,
	  	}, {
	  		cancelToken: cancelTokenSource.token
	  	})
	  	.then((response) => {
	  		// Reset form
	  		idRef.current.value = "";
	  		nameRef.current.value = "";
	  		addressRef.current.value = "";
	  		postalcodeRef.current.value = "";
	  		provinceRef.current.value = "";
	  		cityRef.current.value = "";
	  		octanIdRef.current.value = "";
	  		ipRef.current.value = "";
	  		ipBackupRef.current.value = "";
	  		portRef.current.value = "";
	  		userRef.current.value = "";
	  		passwordRef.current.value = "";
	  		companyCodeRef.current.value = "";

	  		// Reload stations
  			getStations(); 

  			// Toast
  			showToast('Se han guardado los datos');
	  	})
	  	.catch((error) => {
	  		if ( axios.isCancel(error) ) return;
	  		setErrors(error.response.data.errors);
	  	});		
	}

	function removeStation(station_id) {
		// Confirm
		if ( !window.confirm('¿Seguro que quieres eliminar esta estación?') ) return;

		// Ajax
		axios.post('/stations/delete', {
		    station_id: station_id,
	  	}, {
	  		cancelToken: cancelTokenSource.token
	  	})
	  	.then((response) => {
  			// Toast
  			showToast('Se ha eliminado la estación');
	  	})
	  	.catch((error) => {
	  		// Do nothing
	  	}).then((response) => {
	  		// Reload stations
  			getStations(); 
	  	})	
	}

	// Prepare stations
	let stationsRows = [];
	if ( stations ) stations.forEach((el, idx) => {
		stationsRows.push(
			<tr key={idx}>
				<td>
					{el.name}
					<div style={{fontSize: '10px', lineHeight: '10px', color: 'gray'}}>
						{el.address} {el.postalcode} {el.province} {el.city}
					</div>
				</td>
				<td>{el.octan_id}</td>
				<td>{el.ip}</td>
				<td>{el.ip_backup}</td>
				<td>{el.port}</td>
				<td>{el.user}</td>
				<td>{el.password}</td>
				<td>{el.company_code}</td>
				<td className="text-center">
					<a href="." className={"text-plenoil-primary me-2"} onClick={(e) => getStation(e, el.id)}><FontAwesomeIcon icon={faPencilAlt} /></a>
					<a href="." className="text-danger ms-1" onClick={() => removeStation(el.id)}><FontAwesomeIcon icon={faTimes} /></a>
				</td>
			</tr>
		);
	});
	if ( !stations.length ) stationsRows.push(<tr key="empty"><td colSpan="100%">No hay estaciones</td></tr>);

	return (
		<AppLayout>
			<GlobalStyle />

			<div className="col-md-12 mb-3">
				<h4 className="mb-0">Listado de estaciones</h4>
				<small>En este listado se encuentran las direcciones IP de todas las estaciones que recibiran las peticiones de cámbios de precios desde Business Central</small>
			</div>

			<div className="col-md-9 mb-3">
				<div className="table-responsive">
					<table className="table table-bordered bg-white">
						<thead>
							<tr className="bg-plenoil-primary text-white">
								<th>Nombre</th>
								<th>Octan ID</th>
								<th>IP</th>
								<th>IP Backup</th>
								<th>Puerto</th>
								<th>Usuario</th>
								<th>Contraseña</th>
								<th>Código Empresa</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{ stationsRows }
						</tbody>
					</table>
				</div>
			</div>

			<div className="col-md-3 mb-3">
				<div className={'card'}>
					<div className="card-header">
						Añadir nueva estación
					</div>
					<div className="card-body">
						<form id="form-add-edit">
							<input type="hidden" ref={idRef} />
		        			<div className={'mb-3 ' + (errors.name ? 'text-danger' : '')}>
		        				<label htmlFor="name">Nombre</label>
		        				<input ref={nameRef} type="text" id="name" className={'form-control form-control-sm ' + (errors.name ? 'border-danger' : '')} />
		        				{ 
		        					errors.name?.map((el, idx) => {
		        						return <div key={idx}>· {el}</div>;
		        					})
		        				}
		        			</div>
		        			<div className={'mb-3 ' + (errors.address ? 'text-danger' : '')}>
		        				<label htmlFor="address">Dirección</label>
		        				<input ref={addressRef} type="text" id="address" className={'form-control form-control-sm ' + (errors.address ? 'border-danger' : '')} />
		        				{ 
		        					errors.address?.map((el, idx) => {
		        						return <div key={idx}>· {el}</div>;
		        					})
		        				}
		        			</div>
		        			<div className={'mb-3 ' + (errors.postalcode ? 'text-danger' : '')}>
		        				<label htmlFor="postalcode">C.Postal</label>
		        				<input ref={postalcodeRef} type="text" id="postalcode" className={'form-control form-control-sm ' + (errors.postalcode ? 'border-danger' : '')} />
		        				{ 
		        					errors.postalcode?.map((el, idx) => {
		        						return <div key={idx}>· {el}</div>;
		        					})
		        				}
		        			</div>
		        			<div className={'mb-3 ' + (errors.province ? 'text-danger' : '')}>
		        				<label htmlFor="province">Provincia</label>
		        				<input ref={provinceRef} type="text" id="province" className={'form-control form-control-sm ' + (errors.province ? 'border-danger' : '')} />
		        				{ 
		        					errors.province?.map((el, idx) => {
		        						return <div key={idx}>· {el}</div>;
		        					})
		        				}
		        			</div>
		        			<div className={'mb-3 ' + (errors.city ? 'text-danger' : '')}>
		        				<label htmlFor="city">Ciudad</label>
		        				<input ref={cityRef} type="text" id="city" className={'form-control form-control-sm ' + (errors.city ? 'border-danger' : '')} />
		        				{ 
		        					errors.city?.map((el, idx) => {
		        						return <div key={idx}>· {el}</div>;
		        					})
		        				}
		        			</div>
		        			<div className={'mb-3 ' + (errors.octan_id ? 'text-danger' : '')}>
		        				<label htmlFor="octan_id">Octan ID</label>
		        				<input ref={octanIdRef} type="text" id="octan_id" className="form-control form-control-sm" />
		        				{ 
		        					errors.octan_id?.map((el, idx) => {
		        						return <div key={idx}>· {el}</div>;
		        					})
		        				}
		        			</div>
		        			<div className={'mb-3 ' + (errors.ip ? 'text-danger' : '')}>
		        				<label htmlFor="ip">IP</label>
		        				<input ref={ipRef} type="text" id="ip" className="form-control form-control-sm" />
		        				{ 
		        					errors.ip?.map((el, idx) => {
		        						return <div key={idx}>· {el}</div>;
		        					})
		        				}
		        			</div>
		        			<div className={'mb-3 ' + (errors.ip_backup ? 'text-danger' : '')}>
		        				<label htmlFor="ipbackup">IP Backup</label>
		        				<input ref={ipBackupRef} type="text" id="ipbackup" className="form-control form-control-sm" />
		        				{ 
		        					errors.ip_backup?.map((el, idx) => {
		        						return <div key={idx}>· {el}</div>;
		        					})
		        				}
		        			</div>
		        			<div className={'mb-3 ' + (errors.port ? 'text-danger' : '')}>
		        				<label htmlFor="port">Puerto</label>
		        				<input ref={portRef} type="text" id="port" className="form-control form-control-sm" />
		        				{ 
		        					errors.port?.map((el, idx) => {
		        						return <div key={idx}>· {el}</div>;
		        					})
		        				}
		        			</div>
		        			<div className={'mb-3 ' + (errors.user ? 'text-danger' : '')}>
		        				<label htmlFor="user">Usuario</label>
		        				<input ref={userRef} type="text" id="user" className="form-control form-control-sm" />
		        				{ 
		        					errors.user?.map((el, idx) => {
		        						return <div key={idx}>· {el}</div>;
		        					})
		        				}
		        			</div>
		        			<div className={'mb-3 ' + (errors.password ? 'text-danger' : '')}>
		        				<label htmlFor="password">Contraseña</label>
		        				<input ref={passwordRef} type="text" id="password" className="form-control form-control-sm" />
		        				{ 
		        					errors.password?.map((el, idx) => {
		        						return <div key={idx}>· {el}</div>;
		        					})
		        				}
		        			</div>
		        			<div className={'mb-3 ' + (errors.company_code ? 'text-danger' : '')}>
		        				<label htmlFor="company_code">Código empresa</label>
		        				<input ref={companyCodeRef} type="text" id="company_code" className="form-control form-control-sm" />
		        				{ 
		        					errors.company_code?.map((el, idx) => {
		        						return <div key={idx}>· {el}</div>;
		        					})
		        				}
		        			</div>
		        		</form>
					</div>
					<div className="card-footer">
						<button type="submit" className="btn btn-sm btn-plenoil-orange float-end" onClick={() => saveStation()}>Guardar</button>
					</div>
				</div>
			</div>

	        <div className="position-fixed bottom-0 end-0" style={{zIndex: 5000000}}>
		        <div className="toast float-end hide mb-2" role="alert" aria-live="assertive" aria-atomic="true" ref={toastRef}>
					<div className="toast-header">
						<strong className="me-auto">Estaciones</strong>
						<small className="text-muted">ahora mismo</small>
						<button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
					</div>
					<div className="toast-body">
						{toast}
					</div>
				</div>
			</div>
		</AppLayout>
	);
}