import React, { useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import { AppContext } from 'state';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt, faBars } from '@fortawesome/free-solid-svg-icons'
import { createGlobalStyle } from 'styled-components';
import { assets } from 'helpers';

// Style
const GlobalStyle = createGlobalStyle`
	html, body {
		background: var(--plenoil-background-gray);
	}

	.navbar {
		background: var(--plenoil-blue);
		border-bottom-left-radius: 20px;
		border-bottom-right-radius: 20px;

		.navbar-brand {
			margin: 0;
			padding: 0;
			margin-right: 60px;
			display: flex;
			flex-direction: column;

			img {
				width: 80px;
			}

			div {
				font-weight: bold;
				color: var(--plenoil-white);
				font-size: 14px;
			}
		}

		.navbar-toggler {
			outline: none !important;
			box-shadow: none !important;

			svg path {
				color: white !important;
			}
		}

		.navbar-nav {
			width: 100%;

			a {
				color: var(--plenoil-white);
				font-weight: 300;

				&:hover, 
				&.active {
					color: var(--plenoil-yellow);
				}
			}

			.sign-out {
				@media (min-width: 992px) {
					margin-left: auto;
					display: inline-flex;
					align-items: center;
				}

				a {
					color: var(--plenoil-white);
				}
			}
		}			
	}

	#content {
		margin-top: 70px;
	}
`;

export default function AppLayout(props) {
	// State
	const [navbarCollapse, setNavbarCollapse] = useState(true);
	const { dispatch } = useContext(AppContext);

	function logout(e) {
		e.preventDefault();
		dispatch({type: 'setUser', data: null});
		dispatch({type: 'setToken', data: null});
		window.location.reload();
	}

	return (
		<React.Fragment>
			<GlobalStyle />
			<nav className="navbar fixed-top navbar-expand-lg">
				<div className="container">
		        	<NavLink to="/" exact className="navbar-brand" activeClassName="">
		        		<img src={assets("/img/logo_white.png")} alt="PLENOIL" />
		        		<div>MIDDLEWARE</div>
		        	</NavLink>
					
					<button onClick={() => setNavbarCollapse(!navbarCollapse)} className="navbar-toggler" type="button">
						<FontAwesomeIcon icon={faBars} />
				    </button>

				    <div className={(navbarCollapse ? 'collapse' : '') + ' navbar-collapse'}>
				      	<ul className="navbar-nav me-auto mb-2 mb-lg-0">
					        <li className="nav-item">
					        	<NavLink to="/" exact className="nav-link" activeClassName="active">Dashboard</NavLink>
					        </li>
					        <li className="nav-item">
					        	<NavLink to="/alvic-to-dyngas" className="nav-link" activeClassName="active">Alvic 🠖 Dyngas</NavLink>
					        </li>
					        <li className="nav-item">
					        	<NavLink to="/dyngas-to-alvic" className="nav-link" activeClassName="active">Dyngas 🠖 Alvic</NavLink>
					        </li>
							<li className="nav-item">
					        	<NavLink to="/alvic-to-manager" className="nav-link" activeClassName="active">Alvic 🠖 Manager</NavLink>
					        </li>
							<li className="nav-item">
					        	<NavLink to="/lavaderos-to-dyngas" className="nav-link" activeClassName="active">Lavaderos 🠖 Dyngas</NavLink>
					        </li>
					        <li className="nav-item">
					        	<NavLink to="/users" className="nav-link" activeClassName="active">Usuarios</NavLink>
					        </li>
					        <li className="nav-item">
					        	<NavLink to="/stations" className="nav-link" activeClassName="active">Estaciones</NavLink>
					        </li>
					        <li className="nav-item">
					        	<NavLink to="/stations-warnings" className="nav-link" activeClassName="active">Avisos</NavLink>
					        </li>
					        <li className="nav-item">
					        	<NavLink to="/companies" className="nav-link" activeClassName="active">Empresas</NavLink>
					        </li>
					        <li className="nav-item sign-out">
					        	<a href="/" onClick={(e) => logout(e)}>
									<FontAwesomeIcon icon={faSignOutAlt} />
								</a>
					        </li>
					    </ul>
				    </div>
				</div>
			</nav>
			<div className="container" id="content">
				<div className="row">
					{props.children}
				</div>
			</div>
		</React.Fragment>
	);
}