import React, { useState, useRef, useContext, useEffect } from "react";
import AppLayout from 'layouts/AppLayout';
import { createGlobalStyle } from 'styled-components';
import { AppContext } from 'state';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { Toast } from 'bootstrap';

const GlobalStyle = createGlobalStyle`
	table {
		th {
			white-space: nowrap;

			&:nth-child(1) {
				width: 20px;
			}

			&:nth-child(2) {
				width: 20px;
			}

			&:nth-child(3) {
				width: 200px;
			}

			&:nth-child(4) {
				width: 100px;
			}

			&:nth-child(5) {
				width: 40px;					
			}
		}

		th,td {
			vertical-align: middle;
		}
	}
`;

// Axios cancel token
let cancelTokenSource = null;

export default function Users() {
	// State
	const [ users, setUsers ] = useState([]);
	const [ errors, setErrors ] = useState({});
	const [ toast, setToast ] = useState(false);
	const { state } = useContext(AppContext);

	// Ref
	const idRef = useRef(null);
	const roleRef = useRef(null);
	const nameFullRef = useRef(null);
	const nameRef = useRef(null);
	const passwordRef = useRef(null);
	const toastRef = useRef(null);

	useEffect(() => {
  		cancelTokenSource = axios.CancelToken.source();

  		getUsers(); 

  		return function cleanup() {
           	cancelTokenSource.cancel();
        }
  	}, []);

  	useEffect(() => {
        let bsToast = Toast.getInstance(toastRef.current);
        if ( !bsToast ) {
            // initialize Toast
            bsToast = new Toast(toastRef.current)
            // hide after init
            bsToast.hide()
            setToast(false)
        } else {
            // toggle
            toast ? bsToast.show() : bsToast.hide()
        }
  	}, [toast]); 

  	function showToast(msg) {
  		setToast(msg);
  		setTimeout(() => {
  			setToast(false);
  		}, 3000);
  	}

  	function getUsers() {
  		// Ajax
		axios.get('/users/list', {
			cancelToken: cancelTokenSource.token
		})
	  	.then((response) => {
	  		setUsers(response.data);
	  	})
	  	.catch((error) => {
	  		if ( axios.isCancel(error) ) return;
	  		setUsers([]);
	  	});		
  	}

	function getUser(event, id) {
		event.preventDefault();

		// Remove errors
		setErrors({});

		// Ajax
		axios.get('/users/get/' + id, {
		    cancelToken: cancelTokenSource.token
	  	})
	  	.then((response) => {
	  		// Set data
	  		idRef.current.value = response.data.id;
	  		roleRef.current.value = response.data.role;
	  		nameFullRef.current.value = response.data.name_full;
	  		nameRef.current.value = response.data.name;
	  		passwordRef.current.value = "";

			// Scroll to top
			window.scrollTo(0, 0);
	  	});	
	}

	function saveUser() {
		// Remove errors
		setErrors({});

		// Ajax
		axios.post('/users/save', {
		    id: idRef.current.value,
		    role: roleRef.current.value,
		    name_full: nameFullRef.current.value,
		    name: nameRef.current.value,
		    password: passwordRef.current.value
	  	}, {
			cancelToken: cancelTokenSource.token
	  	})
	  	.then((response) => {
	  		// Reset form
	  		idRef.current.value = "";
	  		roleRef.current.value = "";
	  		nameFullRef.current.value = "";
	  		nameRef.current.value = "";
	  		passwordRef.current.value = "";

	  		// Reload users
  			getUsers(); 

  			// Toast
  			showToast('Se han guardado los datos');
	  	})
	  	.catch((error) => {
	  		if ( axios.isCancel(error) ) return;
	  		setErrors(error.response.data.errors);
	  	});		
	}

	function removeUser(event, user_id) {
		event.preventDefault();

		// Confirm
		if ( !window.confirm('¿Seguro que quieres eliminar este usuario?') ) return;

		// Ajax
		axios.post('/users/delete', {
		    user_id: user_id,
	  	}, {
	  		cancelToken: cancelTokenSource.token
	  	})
	  	.then((response) => {
  			// Toast
  			showToast('Se ha eliminado el usuario');
	  	})
	  	.catch((error) => {
	  		// Do nothing
	  	}).then((response) => {
	  		// Reload users
  			getUsers(); 
	  	})	
	}

	// Prepare users
	let usersRows = [];
	if ( users ) users.forEach((el, idx) => {
		usersRows.push(
			<tr key={idx}>
				<td>{el.name_full}</td>
				<td style={{color: 'var(--plenoil-blue)'}}>{el.name}</td>
				<td>{el.role}</td>
				<td>********</td>
				<td className="text-center">
					<a href="." className={"text-plenoil-primary me-2"} onClick={(e) => getUser(e, el.id)}><FontAwesomeIcon icon={faPencilAlt} /></a>
					<a href="." className={"text-danger ms-1 " + (el.id === state.user.id ? 'disabled' : '')} onClick={(e) => removeUser(e, el.id)}><FontAwesomeIcon icon={faTimes} /></a>
				</td>
			</tr>
		);
	});
	if ( !users.length ) usersRows.push(<tr key="empty"><td colSpan="100%">No hay usuarios</td></tr>);

	return (
		<AppLayout>
			<GlobalStyle />

			<div className="col-md-12 mb-3">
				<h4 className="mb-0">Listado de usuarios</h4>
			</div>

			<div className="col-md-8 mb-3">
				<div className="table-responsive">
					<table className="table table-bordered bg-white">
						<thead>
							<tr className="bg-plenoil-primary text-white">
								<th>Nombre</th>
								<th>Alias (login)</th>
								<th>Rol</th>
								<th>Contraseña</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{ usersRows }
						</tbody>
					</table>
				</div>
			</div>

			<div className="col-md-4 mb-3">
				<div className={'card'}>
					<div className="card-header">
						Añadir/editar usuario
					</div>
					<div className="card-body">
						<form id="form-add-edit">
						<input type="hidden" ref={idRef} defaultValue={null} />
		        			<div className={'mb-3 ' + (errors?.role ? 'text-danger' : '')}>
		        				<label htmlFor="role">Rol</label>
		        				<select ref={roleRef} id="role" className={'form-control form-control-sm ' + (errors?.role ? 'border-danger' : '')}>
		        					<option value="admin">admin</option>
		        					<option value="octan">octan</option>
		        					<option value="bcentral">bcentral</option>
		        					<option value="lavadero">lavadero</option>
		        				</select>
		        				{ 
		        					errors?.role?.map((el, idx) => {
		        						return <div key={idx}>· {el}</div>;
		        					})
		        				}
		        			</div>
		        			<div className={'mb-3 ' + (errors?.name_full ? 'text-danger' : '')}>
		        				<label htmlFor="name_full">Nombre</label>
		        				<input ref={nameFullRef} type="text" id="name_full" className={'form-control form-control-sm ' + (errors?.name_full ? 'border-danger' : '')} />
		        				{ 
		        					errors?.name_full?.map((el, idx) => {
		        						return <div key={idx}>· {el}</div>;
		        					})
		        				}
		        			</div>
		        			<div className={'mb-3 ' + (errors?.name ? 'text-danger' : '')}>
		        				<label htmlFor="name">Alias (login)</label>
		        				<input ref={nameRef} type="text" id="name" className={'form-control form-control-sm ' + (errors?.name ? 'border-danger' : '')} />
		        				{ 
		        					errors?.name?.map((el, idx) => {
		        						return <div key={idx}>· {el}</div>;
		        					})
		        				}
		        			</div>
		        			<div className={'mb-3 ' + (errors?.password ? 'text-danger' : '')}>
		        				<label htmlFor="password">Contraseña</label>
		        				<input ref={passwordRef} type="text" id="password" className={'form-control form-control-sm ' + (errors?.password ? 'border-danger' : '')} />
		        				{ 
		        					errors?.password?.map((el, idx) => {
		        						return <div key={idx}>· {el}</div>;
		        					})
		        				}
		        			</div>
		        		</form>
					</div>
					<div className="card-footer">
						<button type="submit" className="btn btn-sm btn-plenoil-orange float-end" onClick={() => saveUser()}>Guardar</button>
					</div>
				</div>
			</div>

	        <div className="position-fixed bottom-0 end-0" style={{zIndex: 5000000}}>
		        <div className="toast float-end hide mb-2" role="alert" aria-live="assertive" aria-atomic="true" ref={toastRef}>
					<div className="toast-header">
						<strong className="me-auto">Estaciones</strong>
						<small className="text-muted">ahora mismo</small>
						<button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
					</div>
					<div className="toast-body">
						{toast}
					</div>
				</div>
			</div>
		</AppLayout>
	);
}