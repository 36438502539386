import React, { useState, useEffect } from "react";
import AppLayout from 'layouts/AppLayout';
import Paginator from 'components/Paginator';
import DetailsRow from 'pages/LavaderosToDyngasLog/details-row';
import { createGlobalStyle } from 'styled-components';
import axios from 'axios';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';

// Global Styles
const GlobalStyle = createGlobalStyle`
	.alert {
		cursor: pointer;
	}

	.statuses-resume {
		padding: 10px;
		background: white;

		.column {
			width: 33.33%;
			padding: 5px;

			&:not(:last-of-type) {
				border-right: 1px solid var(--bs-gray);
			}

			& > .title {
				text-align: center;
				font-size: 20px;
				background: var(--plenoil-blue);
				color: white;
				margin: 5px;
				border-radius: 3px;
			}

			.counters {
				display: flex;
				flex-wrap: wrap;
				cursor: pointer;

				.counter {
					width: 33%;
					border: 1px solid transparent;
					border-radius: 10px;

					&:hover {
						background: var(--bs-light);
						border-color: var(--plenoil-blue-light)
					}

					.title {
						text-align: center;
						font-size: 15px;
						line-height: 15px;
						margin-top: 8px;
					}

					.number {
						text-align: center;
						font-size: 30px;
						line-height: 30px;
					}
				}				
			}
		}
	}

	.card-body {
		padding: 0;

		table#table-rows {
			margin-bottom: 0;

			& > thead {

				tr {
					border-top: 0;

					th {
						background: var(--plenoil-background-gray);
					}
				}
			}

			& > tbody  {
				tr {
					cursor: pointer;

					&.details {
						& > td {
							border-bottom: 10px solid var(--plenoil-blue-light);
							background: #f0f6ff;
							padding: 10px;
						}

						&:not(.show) {
							display: none;
						}
					}

					&.opened {
						background: var(--plenoil-blue-light);

						td:first-of-type {
							border-left: 1px solid var(--plenoil-blue-light);
						}

						td:last-of-type {
							border-right: 1px solid var(--plenoil-blue-light);
						}

					}
				}

				tr:last-of-type {
					border-bottom: 0;
				}
			}

			& > tr {

				td:first-of-type,
				th:first-of-type {
					border-left: 0;
				}
				td:last-of-type,
				th:last-of-type {
					border-right: 0;
				}
			}

			& > tbody > tr:not(.details) {
				user-select: none;
			}

			& > tbody > tr:not(.details):not(.opened):hover {
				background: var(--plenoil-background);
			}
		}
	}
`;

// Axios cancel token
let cancelTokenSource = null;

export default function LavaderosToDyngasLog() {
	// State
	const [logs, setLogs] = useState([]);
	const [visibleDetails, setVisibleDetails] = useState([]);
	const [documents, setDocuments] = useState([]);
	const [logStatuses, setLogStatuses] = useState([]);
	const [logAlerts, setLogAlerts] = useState([]);
	const [statusesResume, setStatusesResume] = useState([]);
	const [filters, setFilters] = useState({
		document: '',
		status: '',
		limit: 50,
		date_from: moment().format('yyyy-MM-DD') + 'T00:00',
		date_to: moment().format('yyyy-MM-DD') + 'T23:59',
	});
	const [stations, setStations] = useState([]);

  	async function getDocuments() {
		return axios.get('/lavaderos-logs/get-documents', {
			cancelToken: cancelTokenSource.token
		})
	  	.then((response) => {
	  		setDocuments([...response.data]);
	  	})
	  	.catch((error) => {
	  		if ( axios.isCancel(error) ) return;
	  		setDocuments([]);
	  	});
  	};

  	async function getLogStatuses() {
  		return axios.get('/lavaderos-logs/get-log-statuses', {
			cancelToken: cancelTokenSource.token
		})
	  	.then((response) => {
	  		setLogStatuses({...response.data});
	  	})
	  	.catch((error) => {
	  		if ( axios.isCancel(error) ) return;
	  		setLogStatuses({});
	  	});
  	};

  	async function getLogAlerts() {
		return axios.get('/lavaderos-logs/get-alerts', {
			cancelToken: cancelTokenSource.token
		})
	  	.then((response) => {
	  		setLogAlerts([...response.data]);
	  	})
	  	.catch((error) => {
	  		if ( axios.isCancel(error) ) return;
	  		setLogAlerts([]);
	  	});
  	};

	async function getStations() {
		return axios.get('/stations/list', {
			params: {},
			cancelToken: cancelTokenSource.token
		})
	  	.then((response) => {
	  		setStations([...response.data]);
	  	})
	  	.catch((error) => {
	  		if ( axios.isCancel(error) ) return;
	  		setStations([]);
	  	});		
  	};

  	async function getStatusesResume(filters) {
		return axios.get('/lavaderos-logs/get-statuses-resume', {
			params: {
				document: filters.document,
				date_from: filters.date_from,
				date_to: filters.date_to,
				status: filters.status,
				station_id: filters.station_id
			},
			cancelToken: cancelTokenSource.token
		})
	  	.then((response) => {
	  		setStatusesResume({...response.data});
	  	})
	  	.catch((error) => {
	  		if ( axios.isCancel(error) ) return;
	  		setStatusesResume({});
	  	});
  	};

  	async function getLogs(page, filters) {
		return axios.get('/lavaderos-logs/list', {
			params: {
				page: page,
				document: filters.document,
				limit: filters.limit,
				date_from: filters.date_from,
				date_to: filters.date_to,
				status: filters.status,
				station_id: filters.station_id
			},
			cancelToken: cancelTokenSource.token
		})
	  	.then((response) => {
	  		setLogs({...response.data});
	  	})
	  	.catch((error) => {
	  		if ( axios.isCancel(error) ) return;
	  		setLogs([]);
	  	});		
  	};

  	// Did mount
	useEffect(() => {
		// Set axios cancel token
		cancelTokenSource = axios.CancelToken.source();
		
		// Get data
		async function getData() {
			await getDocuments();
			await getLogStatuses();
			await getStations();
			await getLogAlerts();
			await getStatusesResume(filters);
		}
		getData();

		// On unmount, cancel any ajax request
  		return function cleanup() {
           	cancelTokenSource.cancel();
        }
  	}, []);

	// Filters update state callback
	useEffect(() => {
		// Get data
		async function getData() {
			await getLogs(1, filters)
			await getStatusesResume(filters);
		}
		getData();
  		
  	}, [filters]);

  	function toggleDetails(idx) {
  		if ( visibleDetails.includes(idx) ) visibleDetails.splice(visibleDetails.indexOf(idx), 1);
  		else visibleDetails.push(idx);
  		setVisibleDetails([...visibleDetails]);
  	}

  	function setFilter(filter, value) {
  		filters[filter] = value;
  		setFilters({...filters});
  	}

  	function setDatePreset(preset) {
  		if ( preset === "" ) {
  			filters['date_from'] = undefined;
  			filters['date_to'] = undefined;
  		}
  		if ( preset === "today" ) {
  			filters['date_from'] = moment().format('yyyy-MM-DD');
  			filters['date_to'] = moment().format('yyyy-MM-DD');
  		}
  		if ( preset === "yesterday" ) {
  			filters['date_from'] = moment().subtract(1, 'days').format('yyyy-MM-DD');
  			filters['date_to'] = moment().subtract(1, 'days').format('yyyy-MM-DD');
  		}
  		if ( preset === "thismonth" ) {
  			filters['date_from'] = moment().startOf('month').format('yyyy-MM-DD');
  			filters['date_to'] = moment().endOf('month').format('yyyy-MM-DD');
  		}
  		if ( preset === "lastmonth" ) {
  			filters['date_from'] = moment().subtract(1, 'months').startOf('month').format('yyyy-MM-DD');
  			filters['date_to'] = moment().subtract(1, 'months').endOf('month').format('yyyy-MM-DD');
  		}
  		if ( preset === "thisyear" ) {
  			filters['date_from'] = moment().startOf('year').format('yyyy-MM-DD');
  			filters['date_to'] = moment().endOf('year').format('yyyy-MM-DD');
  		}
  		if ( preset === "lastyear" ) {
  			filters['date_from'] = moment().subtract(1, 'years').startOf('year').format('yyyy-MM-DD');
  			filters['date_to'] = moment().subtract(1, 'years').endOf('year').format('yyyy-MM-DD');
  		}

  		if ( filters['date_from'] ) filters['date_from'] += 'T00:00';
  		if ( filters['date_to'] ) filters['date_to'] += 'T23:59';

  		setFilters({...filters});
  	}

	function toggleAllrows() {
		let openedRows = [];

		if ( visibleDetails.length < logs.data?.length || visibleDetails.length === 0 ) { // Open all
			openedRows = logs.data?.map((el, idx) => idx);
		}

		setVisibleDetails([...openedRows]);
	}

  	// Prepare rows
  	let rows = [];
  	if ( logs.data ) {
  		logs.data.forEach((el, idx) => {

  			rows.push(
  				<React.Fragment key={idx}>
	  				<tr className={(visibleDetails.includes(idx) ? 'opened' : '')} onClick={(e) => toggleDetails(idx)}>
	  					<td>
	  						<div className="d-flex">
	  							<div className="w-50">
			  						<div>{moment(el.created_at).format('DD-MM-YYYY')}</div>
			  						<small>{moment(el.created_at).format('HH:mm:ss')}</small>
			  					</div>
  								{(() => {
  									if ( el.sent_date ) return (
  										<div className="w-50">
  											<div>{moment(el.sent_date).format('DD-MM-YYYY')}</div>
		  									<small>{moment(el.sent_date).format('HH:mm:ss')}</small>
		  								</div>
  									);
  								})()}
	  						</div>
	  					</td>
	  					<td>{el.document}</td>
	  					<td>
	  						<span className={"badge bg-" + (logStatuses[el.status]?.color)}>{logStatuses[el.status]?.name}</span>
	  					</td>
	  				</tr>
	  				<tr className={'details ' + (visibleDetails.includes(idx) ? 'show' : '')}>
	  					<td colSpan="100%">
	  						{ visibleDetails.includes(idx) ? <DetailsRow id={el._id} logStatuses={logStatuses} /> : '' }
	  					</td>
	  				</tr>
  				</React.Fragment>
  			);
  		});
  	}
  	if ( !rows.length ) rows.push(<tr key="empty"><td colSpan="100%">No hay registros</td></tr>);

  	// Prepare documents
  	let documentsOptions = [];
  	if (documents) documents.forEach((el, idx) => {
  		documentsOptions.push(<option key={idx} value={el}>{el}</option>);
  	});

  	// Prepare lavaderos to bcentral statuses
  	let statuses_rows = [];
  	let last_group = null;
  	for(let i in logStatuses) {
  		// Get group
  		let group = logStatuses[i].group;
  		if ( group === last_group ) continue;
  		last_group = group;

  		// Generate
  		let optgroup = 
			<optgroup key={i} label={group}>
				{(() => {
					let options = []
					for(let e in logStatuses) {
			  			if ( logStatuses[e].group === group ) {
			  				options.push(
			  					<option key={e} value={e}>
			  						{logStatuses[e].name}
			  					</option>
			  				);
			  			}
			  		}
			  		return options;
				})()}
			</optgroup>;

		// Push
		statuses_rows.push(optgroup);
  	}

  	// Prepare resume
  	let statuses_resume = [];
  	for(let idx in statusesResume) {
  		let column = 
  			<div key={idx} className="column">
  				<div className="title">{idx}</div>
  				{
  					(() => {
  						let counters = [];
  						for(let idx2 in statusesResume[idx]) {
  							counters.push(
  								<div 
  									key={idx2} 
  									className={"counter text-" + logStatuses[idx2]?.color} 
  									onClick={(e) => {  
  										let newFilters = {...filters};
  										newFilters['status'] = idx2;
  										newFilters['document'] = '';
  										newFilters['date_from'] = undefined;
  										newFilters['date_to'] = undefined;
  										setFilters(newFilters);
  									}}
  								>
  									<div className="title">
  										{logStatuses[idx2]?.name}
  									</div>
  									<div className="number">
  										{statusesResume[idx][idx2]}
  									</div>
  								</div>
  							);
  						}
  						return <div className="counters">{counters}</div>;
  					})()
  				}
  			</div>;
  		statuses_resume.push(column);
  	}

  	// Render
	return (
		<AppLayout>
			<GlobalStyle />
			<div className="col-md-12 mb-3 text-center">
				<h3 className="mb-0">Lavaderos 🠖 Dyngas</h3>
			</div>

			{ logAlerts.length > 0 &&
				<div className="col-md-12">
					<div className="alert alert-danger" onClick={(e) => setFilter('status', 'with-incidents')}>
						Se han encontrado {logAlerts.length} incidencias para revisar
					</div>
				</div>
			}

			<div className="col-md-12 mb-4">
				<div className="card">
					<div className="card-header statuses-resume d-flex">
						{statuses_resume}
					</div>
					<div className="card-header">
						<div className="row">
							<div className="col-md-2">
								<select className="form-control form-control-sm" value={filters.document} onChange={(e) => setFilter('document', e.target.value)}>
									<option value="">- Documentos -</option>
									{documentsOptions}
								</select>
							</div>
							<div className="col-md-4">
								<div className="input-group input-group-sm">
									<input type="datetime-local" className="form-control form-control-sm" style={{width: '40%', fontSize: '13px'}} value={filters.date_from ?? ''} onChange={(e) => setFilter('date_from', e.target.value)} />
									<input type="datetime-local" className="form-control form-control-sm" style={{width: '40%', fontSize: '13px'}} value={filters.date_to ?? ''} onChange={(e) => setFilter('date_to', e.target.value)} />
									<select className="form-control form-control-sm" style={{width: '20%'}} defaultValue={"today"} onChange={(e) => setDatePreset(e.target.value)}>
										<option value="">- Fechas -</option>
										<option value="today">Hoy</option>
										<option value="yesterday">Ayer</option>
										<option value="thismonth">Este mes</option>
										<option value="lastmonth">Mes pasado</option>
										{/*<option value="thisyear">Este año</option>
										<option value="lastyear">Año pasado</option>*/}
									</select>
								</div>
							</div>
							<div className="col-md-2">
								<select className="form-control form-control-sm" value={filters.status} onChange={(e) => setFilter('status', e.target.value)}>
									<option value="">- Estado -</option>
									{statuses_rows}
								</select>
							</div>
							<div className="col-md-2">
								<select className="form-control form-control-sm" value={filters.station_id} onChange={(e) => setFilter('station_id', e.target.value)}>
									<option value="">- Estaciones -</option>
									{stations?.map((el, idx) => {
										return (
											<option key={idx} value={stations[idx].octan_id}>
												{el.name}
											</option>
										);
									})}
								</select>
							</div>
							<div className="col-md-1">
								<select className="form-control form-control-sm" value={filters.limit} onChange={(e) => setFilter('limit', e.target.value)}>
									{
										[10, 50, 100, 500, 1000].map((el, idx) => {
											return <option key={idx} value={el}>{el}</option>
										})
									}
								</select>
							</div>
							<div className="col-md-1 text-end">
								<button className="btn btn-sm btn-link p-0" onClick={toggleAllrows}><FontAwesomeIcon icon={visibleDetails.length < logs.data?.length ? faCaretDown : faCaretUp} /></button>
							</div>
						</div>
					</div>
					<div className="card-body">
						<div className="table-responsive">
							<table className="table table-bordered" id="table-rows">
								<thead>
									<tr>
										<th style={{width: 250}}>Fecha</th>
										<th style={{width: 150, whiteSpace: 'nowrap'}}>Documento</th>
										<th style={{width: 150}}>Estado</th>
									</tr>
								</thead>
								<tbody>
									{rows}
								</tbody>
							</table>
						</div>
					</div>
					<div className="card-footer d-flex justify-content-end">
						<Paginator
							min={1}
							current={logs.current_page}
							max={logs.last_page}
							changeCallback={(page) => getLogs(page, filters)}
						/>
					</div>
				</div>
			</div>
		</AppLayout>
	);
}